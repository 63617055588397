// UNCOMMENT & REMOVE ESLINT DISABLES AFTER ITS DONE
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { IonCard, IonCardContent, IonInfiniteScroll, IonInfiniteScrollContent, IonList } from '@ionic/react';
import { getFinishedBets } from '../../global/request/bet';
import { AuthContext } from '../authentication/AuthContext';
import FightCard from './FightCard';
import { FightHistoryLoader } from '../skeletalLoaders/SkeletalLoaders';
import generateUniqueKey from '../../UniqueKeyGenerator/UniqueKeyGenerator';

/**
 * Component for displaying a user's fight history in the profile page.
 * @param {Object} props
 * @param {Object} props.record - Object containing the user's record.
 * @param {number} props.record.wins - Number of wins.
 * @param {number} props.record.losses - Number of losses.
 * @param {number} props.record.ties - Number of ties.
 * @param {Object[]} props.loadedFights - Array of fight objects.
 * @param {Function} props.loadMoreFights - Function to load more fights.
 * @returns
 */
// eslint-disable-next-line no-unused-vars
function FightHistory({ record, loadedFights, loadMoreFights, profileId, profileName, routeName }) {
  const [finishedBets, setFinishedBets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  async function fetchFinishedBets() {
    try {
      const { idToken: bearerToken } = authCtx.tokens;
      const bets = await getFinishedBets(bearerToken);
      console.log(bets);

      const sortedBets = bets.sort((a, b) => b.timestamp - a.timestamp);

      setFinishedBets(sortedBets);
      setIsLoading(false);
    } catch (error) {
      console.error('Error loading finished bets:', error);
    }
  }
  console.log('route name', routeName);
  useEffect(() => {
    if (location.pathname !== routeName) {
      return;
    }
    fetchFinishedBets();
  }, [authCtx.tokens, location]);

  return (
    <IonList id="record-list">
      <IonCard id="record-header">
        <IonCardContent className="record-card">
          <h1 className="record-text">
            {record.wins} won - {record.losses} lost - {record.ties} tie
          </h1>
        </IonCardContent>
      </IonCard>
      {isLoading ? (
        <div>
          {Array.from({ length: 8 }).map(() => (
            <FightHistoryLoader key={generateUniqueKey()} />
          ))}
        </div>
      ) : (
        finishedBets.map(fight => (
          <FightCard fight={fight} key={fight.id} profileId={profileId} profileName={profileName} />
        ))
      )}
      <IonInfiniteScroll onIonInfinite={loadMoreFights}>
        <IonInfiniteScrollContent loadingText="Loading" loadingSpinner="" />
      </IonInfiniteScroll>
    </IonList>
  );
}

export default FightHistory;
