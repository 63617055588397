import React from 'react';
import { IonContent, IonList, IonMenuToggle, IonItem, IonLabel, IonButton, IonBadge } from '@ionic/react';
import './RecentChats.scss';

const RecentChats = ({ channels, idData, users, handleChannelClick, hasMore, loadMore, currentChannelId }) => {
  const filteredChannels = channels.map(channel => ({
    ...channel,
    isCurrentChannel: channel.channelId === currentChannelId,
  }));

  return (
    <IonContent>
      <IonList>
        {filteredChannels.map(channel => {
          const userIds = channel.userIds?.filter(id => id !== idData.sub) || [];
          const userDisplayNames = userIds.map(userId => users[userId]?.displayName || 'Loading...').join(', ');
          const unreadCount = channel.unreadCount || 0;

          return (
            <IonMenuToggle key={channel.channelId} autoHide={false}>
              <IonItem
                button
                onClick={() =>
                  handleChannelClick(
                    channel.channelId,
                    channel.metadata?.displayName || userDisplayNames || 'Loading...',
                  )
                }
              >
                <IonLabel>{channel.metadata?.displayName || userDisplayNames || 'Loading...'}</IonLabel>
                {unreadCount > 0 && (
                  <IonBadge color="danger" style={{ marginLeft: '8px' }}>
                    {unreadCount}
                  </IonBadge>
                )}
              </IonItem>
            </IonMenuToggle>
          );
        })}
      </IonList>
      {hasMore && <IonButton onClick={loadMore}>Load More</IonButton>}
    </IonContent>
  );
};

export default RecentChats;
