import React, { useEffect, useState } from 'react';

import GoogleAutocomplete from '../../googleMapsApi/GoogleAutocomplete';

import '../../styles/addressFields/AddressFields.scss';

const AddressFields = ({
  addressDetails,
  setAddressDetails,
  setIsFormValid,
  showAutocompleteDefault = true,
  className = '',
}) => {
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const requiredAddressFields = ['streetAddress', 'city', 'state', 'zip'];

  const [showAutocomplete, setShowAutocomplete] = useState(showAutocompleteDefault);

  const trySetIsFormValid = val => {
    if (typeof setIsFormValid === 'function') {
      setIsFormValid(val);
    }
  };

  const handleInputChange = (field, value) => {
    if (field === 'zip') {
      const cleanedValue = value.replace(/\D/g, '').slice(0, 5);
      value = cleanedValue;
    }

    const updatedDetails = { ...addressDetails, [field]: value };
    setAddressDetails(updatedDetails);
  };

  // GOOGLE AUTOCOMPLETE

  const handlePlaceSelect = place => {
    const addressComponents = place.address_components.reduce((acc, { types, long_name, short_name }) => {
      if (types.includes('street_number')) acc.streetNumber = long_name;
      if (types.includes('route')) acc.street = long_name;
      if (types.includes('locality') || (types.includes('sublocality') && !acc.city)) acc.city = long_name;
      if (types.includes('administrative_area_level_1')) acc.state = short_name;
      if (types.includes('postal_code')) acc.zip = long_name;
      return acc;
    }, {});

    const addr = {
      ...addressDetails,
      streetAddress: `${addressComponents.streetNumber || ''} ${addressComponents.street || ''}`.trim(),
      city: addressComponents.city || '',
      state: addressComponents.state || '',
      zip: addressComponents.zip || '',
    };
    setAddressDetails(addr);
    setShowAutocomplete(false);
  };

  /// END OF GOOGLE AUTOCOMPLETE

  useEffect(() => {
    let addr = {};
    if (typeof addressDetails === 'object') {
      addr = { ...addressDetails };
    }
    trySetIsFormValid(requiredAddressFields.every(key => addr[key]?.trim() !== ''));
  }, [addressDetails]);

  return (
    <div className={`inputs-wrapper ${className}`}>
      {showAutocomplete ? (
        <GoogleAutocomplete
          apiKey={googleApiKey}
          placeholder="Search Address or Place"
          onSelect={handlePlaceSelect}
          className="address-field-input autocomplete-input"
          style={{ width: '100%' }}
        />
      ) : (
        <div className="toggle-autocomplete-text-container show-autocomplete-text-container">
          <button type="button" onClick={() => setShowAutocomplete(true)} className="toggle-autocomplete-text">
            Search Your Address with Google Autocomplete
          </button>
        </div>
      )}
      {!showAutocomplete ? (
        <>
          <input
            type="text"
            className="address-field-input street-address-field-input"
            value={addressDetails.streetAddress}
            placeholder="Street"
            onChange={e => handleInputChange('streetAddress', e.target.value)}
          />
          <input
            type="text"
            className="address-field-input"
            value={addressDetails.apt}
            placeholder="Apartment"
            onChange={e => handleInputChange('apt', e.target.value)}
          />
          <input
            type="text"
            className="address-field-input"
            value={addressDetails.city}
            placeholder="City"
            onChange={e => handleInputChange('city', e.target.value)}
          />
          <input
            type="text"
            className="address-field-input"
            value={addressDetails.state}
            placeholder="State"
            onChange={e => handleInputChange('state', e.target.value)}
          />
          <input
            type="text"
            className="address-field-input"
            value={addressDetails.zip}
            placeholder="ZIP Code"
            onChange={e => handleInputChange('zip', e.target.value)}
          />
        </>
      ) : (
        <div className="toggle-autocomplete-text-container">
          <button type="button" onClick={() => setShowAutocomplete(false)} className="toggle-autocomplete-text">
            Manually Enter Address Fields
          </button>
        </div>
      )}
    </div>
  );
};

export default AddressFields;
