import React from 'react';
import { IonCard, IonSkeletonText } from '@ionic/react';

export const GamesListSkeletonLoader = () => (
  <IonCard>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '60px',
        paddingTop: '2px',
        paddingBottom: '2px',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: '32%',
          height: '100%',
          clipPath: 'polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%)',
          borderRadius: '8px',
          marginLeft: '10px',
          paddingBottom: '8px',
          paddingTop: '1px',
        }}
      >
        <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
      </div>
      <div
        style={{
          width: '32%',
          height: '100%',
          clipPath: 'polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%)',
          borderRadius: '8px',
          position: 'relative',
          right: '20px',
          paddingBottom: '9px',
          top: '1px',
        }}
      >
        <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
      </div>
      <div
        style={{
          width: '17%',
          height: '100%',
          borderRadius: '8px',
          paddingBottom: '9px',
          position: 'relative',
          right: '6%',
          paddingTop: '1px',
        }}
      >
        <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
      </div>
      <div
        style={{
          width: '12%',
          height: '100%',
          borderRadius: '8px',
          paddingBottom: '9px',
          position: 'relative',
          right: '6%',
          paddingTop: '1px',
        }}
      >
        <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  </IonCard>
);

export const MatchmakingFightCardLoader = () => (
  <IonCard>
    <div style={{ position: 'relative', padding: '2px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '2px' }}>
        <IonSkeletonText
          animated
          style={{
            width: '50%',
            height: '16px',
            borderRadius: '4px',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '70px',
          paddingTop: '2px',
          paddingBottom: '2px',
          borderRadius: '8px',
          overflow: 'hidden',
          width: '80%',
        }}
      >
        <div
          style={{
            width: '35%',
            height: '100%',
            clipPath: 'polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%)',
            borderRadius: '8px',
            marginLeft: '10px',
            paddingBottom: '8px',
            paddingTop: '1px',
          }}
        >
          <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
        </div>
        <div
          style={{
            width: '35%',
            height: '100%',
            clipPath: 'polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%)',
            borderRadius: '8px',
            position: 'relative',
            right: '20px',
            paddingBottom: '9px',
            top: '1px',
          }}
        >
          <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
        </div>
        <div
          style={{
            width: '15%',
            height: '100%',
            borderRadius: '8px',
            paddingBottom: '9px',
            position: 'relative',
            right: '6%',
            paddingTop: '1px',
          }}
        >
          <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
        </div>
      </div>
      <div
        style={{
          width: '20%',
          height: '100%',
          position: 'absolute',
          top: '-2px',
          right: '0',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  </IonCard>
);

export const SingleLineLoader = () => (
  <IonCard
    style={{
      padding: '16px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '60px',
      width: '48%',
      margin: '0',
      marginBottom: '3px',
    }}
  >
    <IonSkeletonText
      animated
      style={{
        width: '80%',
        height: '16px',
        margin: '0 auto',
        borderRadius: '4px',
      }}
    />
  </IonCard>
);

export const SingleBoxLoader = () => (
  <IonCard
    style={{
      padding: '2px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '60px',
      width: '23%',
      alignItems: 'center',
      margin: '1%,',
    }}
  >
    <IonSkeletonText
      animated
      style={{
        width: '80%',
        height: '100%',
        borderRadius: '4px',
        alignItems: 'center',
      }}
    />
  </IonCard>
);

export const FightHistoryLoader = () => (
  <IonCard style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '8px' }}>
    <div
      style={{
        width: '15%',
        height: '60px',
        borderRadius: '8px 0 0 8px',
        marginRight: '8px',
        overflow: 'hidden',
      }}
    >
      <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
    </div>
    <div style={{ flex: '1', marginRight: '8px' }}>
      <div style={{ marginBottom: '8px' }}>
        <IonSkeletonText animated style={{ width: '80%', height: '12px', borderRadius: '4px' }} />
      </div>
      <div style={{ marginBottom: '8px' }}>
        <IonSkeletonText animated style={{ width: '60%', height: '12px', borderRadius: '4px' }} />
      </div>
      <div>
        <IonSkeletonText animated style={{ width: '40%', height: '12px', borderRadius: '4px' }} />
      </div>
    </div>
    <div style={{ width: '20%' }}>
      <div style={{ marginBottom: '8px' }}>
        <IonSkeletonText animated style={{ width: '100%', height: '12px', borderRadius: '4px' }} />
      </div>
      <div>
        <IonSkeletonText animated style={{ width: '80%', height: '12px', borderRadius: '4px' }} />
      </div>
    </div>
  </IonCard>
);

export const LiveFightsLoader = () => (
  <IonCard style={{ padding: '12px' }}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        marginBottom: '8px',
        position: 'relative',
        right: '8%',
      }}
    >
      <IonSkeletonText animated style={{ width: '30%', height: '12px', borderRadius: '4px' }} />
      <IonSkeletonText animated style={{ width: '30%', height: '12px', borderRadius: '4px' }} />
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
      <div style={{ flex: '1', marginRight: '8px', borderRadius: '8px', overflow: 'hidden' }}>
        <IonSkeletonText animated style={{ width: '100%', height: '40px', borderRadius: '8px' }} />
      </div>
      <div style={{ flex: '1', marginRight: '8px', borderRadius: '8px', overflow: 'hidden' }}>
        <IonSkeletonText animated style={{ width: '100%', height: '40px', borderRadius: '8px' }} />
      </div>
      <div
        style={{
          width: '40px',
          height: '42px',
          borderRadius: '8px',
          overflow: 'hidden',
          position: 'relative',
          bottom: '3px',
        }}
      >
        <IonSkeletonText animated style={{ width: '100%', height: '100%', borderRadius: '8px' }} />
      </div>
    </div>
    <IonSkeletonText animated style={{ width: '80%', height: '14px', borderRadius: '4px' }} />
  </IonCard>
);

export const EditProfileLoader = () => (
  <IonCard style={{ padding: '12px' }}>
    <div
      style={{
        width: '100%',
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        overflow: 'hidden',
      }}
    >
      <IonSkeletonText animated style={{ width: '100%', height: '56px', borderRadius: '24px' }} />
      <IonSkeletonText animated style={{ width: '100%', height: '56px', borderRadius: '24px' }} />
      <IonSkeletonText animated style={{ width: '100%', height: '56px', borderRadius: '24px' }} />
      <IonSkeletonText animated style={{ width: '100%', height: '56px', borderRadius: '24px' }} />
      <IonSkeletonText animated style={{ width: '100%', height: '56px', borderRadius: '24px' }} />
      <IonSkeletonText animated style={{ width: '100%', height: '56px', borderRadius: '24px' }} />
    </div>
  </IonCard>
);

export const FriendsListItemLoader = () => (
  <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
    <IonSkeletonText animated style={{ borderRadius: '10px', height: '2em', padding: '3px', marginBottom: '10px' }} />
  </div>
);

export const PickChallengerItemLoader = () => (
  <IonSkeletonText className="skeletal-fighter-tile" animated style={{ borderRadius: '10px', margin: '0' }} />
);
