import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  IonPage,
  IonModal,
  IonContent,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFooter,
  IonInput,
  IonIcon,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { MessageRepository, MessageContentType, Client, ChannelRepository, UserRepository } from '@amityco/ts-sdk';
import { AuthContext } from '../authentication/AuthContext';
import RecentChats from './RecentChats';
import ChatContent from './ChatContent';
import './Chat.scss';

const Chat = ({ match }) => {
  const { channelId: currentChannelId } = match.params;
  const { idData } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [channels, setChannels] = useState([]);
  const [users, setUsers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const messageSubscriptionRef = useRef(null);
  const channelsFetchedRef = useRef(false);
  const currentChannelRef = useRef(currentChannelId);
  const history = useHistory();
  const location = useLocation();
  const { friendName } = location.state || { friendName: 'Chat' };

  const ensureClientConnected = async () => {
    if (!Client.isConnected()) {
      try {
        await Client.startSession({ userId: idData.sub, displayName: idData.name });
        console.log('Client connected successfully');
      } catch (error) {
        console.error('Client connection failed:', error);
      }
    }
  };

  const fetchMessages = async subChannelId => {
    if (messageSubscriptionRef.current && currentChannelRef.current === subChannelId) {
      console.log('Already subscribed to this channel, skipping fetch...');
      return;
    }

    if (messageSubscriptionRef.current) {
      console.log('Unsubscribing from previous channel...');
      messageSubscriptionRef.current();
    }

    await ensureClientConnected();
    console.log(`Fetching messages for subChannelId: ${subChannelId}`);

    currentChannelRef.current = subChannelId;

    const unsubscribe = MessageRepository.getMessages({ subChannelId }, ({ data: fetchedMessages, loading, error }) => {
      if (error) {
        console.error('Error fetching messages:', error);
      }
      if (loading) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
      if (fetchedMessages) {
        setMessages(fetchedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
      }
    });

    messageSubscriptionRef.current = unsubscribe;
  };

  const fetchUserDetails = async userId => {
    if (users[userId]) return;
    try {
      const { data: user } = await UserRepository.getUser({ userId });
      setUsers(prevUsers => ({ ...prevUsers, [userId]: user }));
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const fetchChannels = async () => {
    if (channelsFetchedRef.current) {
      console.log('Channels already fetched, skipping...');
      return;
    }
    channelsFetchedRef.current = true;

    await ensureClientConnected();
    console.log('Fetching channels...');
    ChannelRepository.getChannels({ membership: 'member', sortBy: 'lastCreated' }, async ({ data, error }) => {
      if (error) {
        console.error('Error fetching channels:', error);
        return;
      }
      if (data) {
        console.log('Fetched channels:', data);
        setChannels(data);

        const userFetchPromises = data.flatMap(channel => {
          const userIds = channel.userIds?.filter(id => id !== idData.sub) || [];
          return userIds.map(userId => fetchUserDetails(userId));
        });

        await Promise.all(userFetchPromises);
      }
    });
  };

  useEffect(() => {
    console.log('Fetching messages for currentChannelId:', currentChannelId);
    fetchMessages(currentChannelId);

    return () => {
      if (messageSubscriptionRef.current) {
        messageSubscriptionRef.current(); // Unsubscribe when component unmounts or channel changes
      }
    };
  }, [currentChannelId]);

  useEffect(() => {
    fetchChannels();
  }, [idData.sub]);

  const sendMessage = async () => {
    if (!messageText.trim() || isSending) return;

    console.log('Send button clicked');
    setIsSending(true);

    const textMessage = {
      subChannelId: currentChannelId,
      dataType: MessageContentType.TEXT,
      data: { text: messageText },
      tags: ['chat'],
      metadata: { data: 'textMessage' },
      userId: idData.sub,
    };

    try {
      console.log('Sending message:', messageText);
      await ensureClientConnected();

      const { data: message } = await MessageRepository.createMessage(textMessage);
      console.log('Message sent successfully:', message);

      setMessages(prevMessages => {
        const combinedMessages = [...prevMessages, message];
        return combinedMessages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      });

      setMessageText('');
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };

  const handleChannelClick = async (channelId, displayName) => {
    console.log('Switching to channel:', channelId);

    try {
      if (!channelsFetchedRef.current) {
        await fetchChannels();
      }

      const channel = channels.find(ch => ch.channelId === channelId);

      if (channel && channel.markAsRead) {
        await channel.markAsRead();
        console.log(`Channel ${channelId} marked as read successfully`);

        setChannels(prevChannels =>
          prevChannels.map(ch => (ch.channelId === channelId ? { ...ch, unreadCount: 0 } : ch)),
        );
      } else {
        console.error(`markAsRead method not found on channel ${channelId}`);
      }
    } catch (error) {
      console.error('Error marking channel as read:', error);
    }

    fetchMessages(channelId);
    history.replace(`/chat/${channelId}`, { friendName: displayName });
    setIsModalOpen(false);
  };

  const openRecentChatsModal = () => {
    console.log('Opening recent chats modal');
    setIsModalOpen(true);
  };

  const closeRecentChatsModal = () => {
    console.log('Closing recent chats modal');
    setIsModalOpen(false);
  };

  const closeChat = () => {
    console.log('Closing chat');
    history.goBack();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="chat-toolbar">
          <IonButton className="close-button" slot="start" onClick={closeChat}>
            <IonIcon icon={arrowBack} />
          </IonButton>
          <IonTitle className="chat-title">{friendName}</IonTitle>
          <IonButton className="recent-chats-button" slot="end" onClick={openRecentChatsModal}>
            History
          </IonButton>
        </IonToolbar>
      </IonHeader>

      <IonContent className="chat-content">
        <ChatContent messages={messages} friendName={friendName} idData={idData} isLoading={isLoading} />
      </IonContent>

      <IonFooter className="chat-content-footer">
        <IonToolbar className="chat-input-wrapper">
          <div className="footer-chat-wrapper">
            <IonInput className="chat-input" value={messageText} onIonChange={e => setMessageText(e.detail.value)} />
            <IonButton className="chat-send-button" onClick={sendMessage} disabled={isSending} />
          </div>
        </IonToolbar>
      </IonFooter>

      <IonModal isOpen={isModalOpen} onDidDismiss={closeRecentChatsModal}>
        <IonHeader>
          <IonToolbar>
            <IonButton slot="start" onClick={closeRecentChatsModal}>
              Close
            </IonButton>
            <IonTitle>Recent Chats</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <RecentChats
            currentChannelId={currentChannelId}
            channels={channels}
            idData={idData}
            users={users}
            handleChannelClick={handleChannelClick}
          />
        </IonContent>
      </IonModal>
    </IonPage>
  );
};

export default Chat;
