import React, { useState, useEffect, useContext } from 'react';
import { IonList, IonItem, IonLabel, IonIcon } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import Header from '../header/Header';
import { ProductTourContext } from '../../global/ProductTourContext';

// eslint-disable-next-line no-unused-vars
const FriendSearchComponent = ({ bearerToken, users, handleUserClick }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { searchBarRef } = useContext(ProductTourContext);

  const handleClick = userId => {
    setSearchQuery('');
    handleUserClick(userId);
  };

  useEffect(() => {
    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = users.filter(
        user =>
          user.name.toLowerCase().includes(lowercasedQuery) || user.username.toLowerCase().includes(lowercasedQuery),
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [searchQuery, users]);

  return (
    <Header
      forwardRef={searchBarRef}
      searchTerm={searchQuery}
      setSearchTerm={setSearchQuery}
      placeholder="Search opponents..."
      debounce={100}
    >
      {searchQuery && (
        <IonList className="search-list" key={searchQuery}>
          {filteredUsers.map(user => (
            <IonItem key={user.id} lines="full" onClick={() => handleClick(user.id)}>
              <div className="search-user" style={{ width: '100%' }}>
                <IonLabel>
                  {user.name} - {user.username}
                </IonLabel>
                <IonIcon icon={arrowForwardOutline} slot="end" />
              </div>
            </IonItem>
          ))}
        </IonList>
      )}
    </Header>
  );
};

export default FriendSearchComponent;
