import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { IonContent, IonPage, IonButton, IonImg, IonList, IonCardContent, IonCard /* , IonIcon */ } from '@ionic/react';
// import { chatboxEllipsesOutline } from 'ionicons/icons';
import '../../styles/firendProfile/friendProfile.scss';
// import instagramIcon from './assets/instagram.png';
// import venmoIcon from './assets/venmo.png';
import gloveIcon from './assets/boxing.png';
// import { PageContext } from '../appTabs/AppTabs';
import { AuthContext } from '../authentication/AuthContext';
// import { StartFightContext } from '../fight/StartFightContext';
import { PageContext } from '../appTabs/AppTabs';
import MatchmakingContext from '../../global/MatchmakingContext';
import useProfile from '../../global/useProfile';
import FriendSearchComponent from './FriendSearchHeader';
import { getFriendsProfile, allUsers, addFriend, removeFriend } from '../../global/request/user';
import { getFriendsProfileHistory } from '../../global/request/bet';
import FightCard from '../profile/FightCard';
import { FightHistoryLoader } from '../skeletalLoaders/SkeletalLoaders';
import UnfollowModal from './UnfollowModal';
import generateUniqueKey from '../../UniqueKeyGenerator/UniqueKeyGenerator';

import profilePic from '../../assets/profiles/foodfight_profile_baseball.png';
import { createConversationChannel, login } from '../../services/amityClient';
// import { getLiveBets } from '../../global/request/bet';
// eslint-disable-next-line no-unused-vars
const HISTORY_SLICE_LENGTH = 6;
const ROUTE_REGEX = /^\/app\/friendprofile\/[^/]+$/;

// Mock components to avoid jsx-no-undef errors
// These should eventually be replaced by your actual components
// const LiveFights = () => <div>Live Fights Placeholder</div>;

function FriendProfile() {
  //   // eslint-disable-next-line no-unused-vars
  //   const router = useIonRouter();
  //   const pageCtx = useContext(PageContext);
  //   // eslint-disable-next-line no-unused-vars
  //   const [reload, profileData] = useProfile();
  //   // eslint-disable-next-line no-unused-vars
  //   const [loadedFights, setLoadedFights] = useState([]);
  //   const [liveFights, setLiveFights] = useState([]);
  //   useEffect(() => {
  //     (async () => {
  //       setLiveFights(await getLiveBets(authCtx.tokens.idToken));
  //     })();
  //   }, []);

  // const location = useLocation();
  // const { friendProfileData } = location.state || {};
  // const startFightCtx = useContext(StartFightContext);
  // const { modals } = useContext(PageContext);
  const authCtx = useContext(AuthContext);
  const pageCtx = useContext(PageContext);
  const location = useLocation();
  const [friendProfileData, setFriendProfileData] = useState(null);
  const params = useParams();
  const { dispatch } = useContext(MatchmakingContext);
  const bearerToken = authCtx.tokens.idToken;
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [fightHistory, setFightHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [reloadProfile, selfProfile] = useProfile();
  const [isFollowing, setIsFollowing] = useState(null);
  const [isFollowingChanging, setIsFollowingChanging] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isSelf, setIsSelf] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    const ensureLogin = async () => {
      if (authCtx.accessData && authCtx.accessData.sub && bearerToken) {
        await login(authCtx.accessData.sub, authCtx.idData.name, bearerToken);
      }
    };

    ensureLogin();
  }, [authCtx, bearerToken]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (location.state?.allUsers !== undefined) {
        setUsers(location.state.allUsers);
      } else {
        try {
          const fetchedUsers = await allUsers(bearerToken);
          setUsers(fetchedUsers);
        } catch (error) {
          console.error(error);
        }
      }
    };

    const fillFriendProfileData = async () => {
      if (location.state?.friendProfileData !== undefined) {
        setFriendProfileData(location.state.friendProfileData);
      } else {
        try {
          const friendData = await getFriendsProfile(bearerToken, params.userId);
          setFriendProfileData(friendData);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchUsers();
    fillFriendProfileData();
  }, [bearerToken]);

  const formatName = fullName => {
    if (!fullName) {
      return '';
    }
    const names = fullName.trim().split(' ');
    if (names.length > 1) {
      return `${names[0]} ${names[names.length - 1][0]}.`;
    }
    return fullName;
  };

  // TODO
  const handleFightButtonClick = () => {
    if (friendProfileData) {
      dispatch({
        type: 'SET_CHALLENGER',
        payload: {
          id: friendProfileData.id,
          name: friendProfileData.name,
        },
      });
      if (pageCtx.modals && pageCtx.modals.startFight && pageCtx.modals.startFight.current) {
        pageCtx.modals.startFight.current.present();
      }
    } else {
      console.error('No friendProfileData available to set as challenger.');
    }
  };

  const handleUserClick = async userId => {
    try {
      const friendData = await getFriendsProfile(bearerToken, userId);
      history.push({
        pathname: `/app/friendprofile/${userId}`,
        state: { friendProfileData: friendData, allUsers: users },
      });
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
    }
  };

  const unfollow = async () => {
    if (friendProfileData === null) return;
    setIsFollowingChanging(true);
    try {
      await removeFriend(bearerToken, friendProfileData.id);
      setIsFollowing(false);
      dispatch({ type: 'TRIGGER_GET_FRIENDS_LIST' });
    } catch (error) {
      console.error(error);
    }
    setIsFollowingChanging(false);
  };

  const handleFollowClick = async () => {
    if (friendProfileData === null) return;
    setIsFollowingChanging(true);
    if (isFollowing) {
      try {
        setIsOpen(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        console.log(await addFriend(bearerToken, friendProfileData.id));
        setIsFollowing(true);
        dispatch({ type: 'TRIGGER_GET_FRIENDS_LIST' });
      } catch (error) {
        console.error(error);
      }
    }
    setIsFollowingChanging(false);
  };

  const handleFriendsClick = () => {
    let state;
    // sending allUsersData and friendsIds would be unhelpful if a backend route for getFriends is added
    if (friendProfileData === null) {
      state = null;
    } else {
      state = {
        friendsIds: friendProfileData.friends,
        username: friendProfileData.username,
        allUsersData: users,
      };
    }
    history.push({
      pathname: `/app/friendprofile/${params.userId}/friendslist`,
      state,
    });
  };

  const fetchFriendsProfileHistory = async () => {
    if (friendProfileData === null) return;
    try {
      const response = await getFriendsProfileHistory(bearerToken, friendProfileData.id);
      setFightHistory(response);
      setIsLoading(false);
      setFetchError(false);
    } catch (error) {
      console.error('Failed to fetch friends profile history:', error);
      setFetchError(true);
      console.log(fetchError);
    }
  };

  useEffect(() => {
    fetchFriendsProfileHistory();
    if (selfProfile && friendProfileData) setIsSelf(selfProfile.id === friendProfileData.id);
  }, [friendProfileData]);

  useEffect(() => {
    if (selfProfile && selfProfile.friends !== undefined) {
      setIsFollowing(selfProfile.friends.some(friendId => friendId === params.userId));
      setIsFollowingChanging(false);
    }
    if (selfProfile) setIsSelf(selfProfile.id === params.userId);
    if (isSelf) {
      setFriendProfileData(selfProfile);
    }
  }, [selfProfile]);

  useEffect(() => {
    if (!ROUTE_REGEX.test(location.pathname)) return;
    reloadProfile();
  }, [location.pathname]);

  const handleChatButtonClick = async () => {
    if (friendProfileData) {
      const userIds = [authCtx.accessData.sub, friendProfileData.id];
      console.log('Creating or finding conversation channel with userIds:', userIds);

      try {
        const channel = await createConversationChannel(userIds, friendProfileData.name);

        if (channel) {
          history.push({
            pathname: `/chat/${channel.channelId}`,
            state: { friendName: friendProfileData.name },
          });
        }
      } catch (error) {
        console.error('Error creating or finding conversation channel:', error);
      }
    } else {
      console.error('No friendProfileData available to start a chat.');
    }
  };

  return (
    <IonPage>
      <FriendSearchComponent bearerToken={bearerToken} users={users} handleUserClick={handleUserClick} />
      <IonContent id="profile-page" className="friend-profile-page">
        <div id="profile-info">
          <div className="row profile-overview">
            <img src={profilePic} id="profile-pic" className={`profile-picture is-self-${isSelf}`} alt="Profile" />
            {isSelf === false && (
              <div className="profile-interactions">
                {/* Commented out for when we add chat feature */}
                {/* <IonButton className="profile-button message-button" mode="ios">
                  <div className="profile-button-inner">
                    <span>Message</span>
                    <IonIcon icon={chatboxEllipsesOutline} size="large" />
                  </div>
                </IonButton> */}
                <IonButton className="col friend-button profile-button" onClick={handleChatButtonClick}>
                  Chat
                </IonButton>
                <IonButton
                  className="profile-button"
                  mode="ios"
                  onClick={handleFightButtonClick}
                  disabled={friendProfileData === null}
                >
                  <div className="profile-button-inner">
                    <span>Fight</span>
                    <IonImg className="glove-icon" src={gloveIcon} alt="Fighting Glove" />
                  </div>
                </IonButton>
              </div>
            )}
          </div>
          <div className="row friends-info-container">
            <div>
              <h1 className="profile-name">{formatName(friendProfileData?.name)}</h1>
              {friendProfileData !== null && (
                <span className="win-streak-text">Win Streak: {friendProfileData.streak}</span>
              )}
            </div>
            <div className="profile-interactions">
              <IonButton className="profile-button friends-button" onClick={handleFriendsClick} mode="ios">
                <div className="profile-button-inner">
                  <span>Friends</span>
                  {friendProfileData !== null && (
                    <span className="friends-amount">{friendProfileData.friends.length}</span>
                  )}
                </div>
              </IonButton>
              {isSelf === false && (
                <IonButton
                  className={`profile-button follow-button-${isFollowing}`}
                  mode="ios"
                  onClick={handleFollowClick}
                  disabled={isFollowingChanging}
                >
                  <div className="profile-button-inner">
                    {/* Weirdness of the next line is to keep button size the same after following */}
                    <span className="invisible-text">
                      Following
                      <span className="follow-button-content">{isFollowing ? 'Following' : 'Follow'}</span>
                    </span>
                    {/* {isFollowing && <IonIcon icon={chevronDownOutline} size="small" />} */}
                  </div>
                </IonButton>
              )}
              {/* <IonButton className="col friend-button friend-fight-button" onClick={handleFightButtonClick}>
                Fight
                <IonImg className="glove-icon" src={gloveIcon} alt="Fight" />
              </IonButton> */}
            </div>
          </div>
        </div>
        <IonList id="record-list" className="record-list">
          <IonCard id="record-header">
            <IonCardContent className="record-card">
              <h1 className="record-text">
                {friendProfileData?.wins} Won - {friendProfileData?.losses} Lost - {friendProfileData?.ties} Tie
              </h1>
            </IonCardContent>
          </IonCard>
          {/* {finishedBets.map(fight => (
        <FightCard fight={fight} key={fight.id} profileId={profileId} profileName={profileName} />
      ))}
      <IonInfiniteScroll onIonInfinite={loadMoreFights}>
        <IonInfiniteScrollContent loadingText="Loading" loadingSpinner="" />
      </IonInfiniteScroll> */}
          <div className="fightHistory">
            {isLoading ? (
              <div>
                {Array.from({ length: 8 }).map(() => (
                  <FightHistoryLoader key={generateUniqueKey()} />
                ))}
              </div>
            ) : (
              fightHistory
                .slice()
                .reverse()
                .map(fight => (
                  <FightCard
                    fight={fight}
                    key={fight.id}
                    profileId={friendProfileData?.id}
                    profileName={formatName(friendProfileData?.name)}
                  />
                ))
            )}
          </div>
        </IonList>
        <UnfollowModal isOpen={isOpen} handleCloseModal={() => setIsOpen(false)} handleUnfollowClick={unfollow} />
      </IonContent>
    </IonPage>
  );
}

export default FriendProfile;
