import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonPage, IonToolbar, IonButtons, IonContent, IonButton, IonTitle, IonIcon, useIonRouter } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';

import '../../styles/profile/EditProfile.scss';

import EntryField from '../authentication/EntryField';
import profilePicPlaceholder from '../../assets/profiles/foodfight_profile_baseball.png';
import useProfile from '../../global/useProfile';
import { EditProfileLoader } from '../skeletalLoaders/SkeletalLoaders';
import { allUsers, updateProfile } from '../../global/request/user';
import { AuthContext } from '../authentication/AuthContext';
import MatchmakingContext from '../../global/MatchmakingContext';
import AddressFields from '../addressFields/AddressFields';
import MessageOverlay from '../fight/MessageOverlay';

const PHONE_EXT_REGEX = /^\s*\+\s*\d/g;
const FORMATTED_ADDR_REGEX = /^.*\s.+,\s.+,\s.+\s.+$/;

const EditProfile = () => {
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // phone number and email are currently not changeable because they would require verification to change
  // some logic related to changing them may exist but the input fields for them are readonly or hidden
  // const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [addressDetails, setAddressDetails] = useState({
    streetAddress: '',
    apt: '',
    city: '',
    state: '',
    zip: '',
  });
  const [isAddressValid, setIsAddressValid] = useState(false);
  // Profile picture mostly implemented using base64 encoding,
  // but using S3 and URLs would probably be better.
  // Saving this for a different task and commenting out the edit photo button
  const [profilePic, setProfilePic] = useState(profilePicPlaceholder);
  const [isLoadingPfp, setIsLoadingPfp] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const overlayRef = useRef(null);
  const [overlayMsg, setOverlayMsg] = useState('');
  const router = useIonRouter();

  // eslint-disable-next-line no-unused-vars
  const [reload, profile] = useProfile();
  const authCtx = useContext(AuthContext);
  const { dispatch } = useContext(MatchmakingContext);
  const bearerToken = authCtx.tokens.idToken;

  // const isValidEmail = emailAddress => {
  //   return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
  // };

  const parsePhoneNumber = str => str?.replace(PHONE_EXT_REGEX, '').replace(/\D/g, '').slice(0, 10);

  // Returns valid address string from addressDetails state value
  const formatAddress = () => {
    if (!isAddressValid) return '';
    const addr = {};
    Object.entries(addressDetails).forEach(([key, value]) => {
      addr[key] = value.trim().replace(/\s+/, ' ');
    });
    return `${addr.apt?.replace(' ', '') || ''} ${addr.streetAddress}, ${addr.city}, ${addr.state} ${addr.zip}`;
  };

  // Takes an address string and returns an object to be set as addressDetails state value
  const parseFormattedAddress = addr => {
    if (!FORMATTED_ADDR_REGEX.test(addr)) return { invalid: true };

    const split = addr.split(', ');
    const leftWords = split[0];
    const leftWordsFirstSpace = leftWords.search(/\s+/);
    const potentialApt = leftWords.slice(0, leftWordsFirstSpace);
    const apt = potentialApt === 'undefined' ? '' : potentialApt;
    const streetAddress = leftWords.slice(leftWordsFirstSpace + 1).trim();
    const city = split[1].trim();
    const [state, zip] = split[2]
      .trim()
      .split(/\s+/)
      .map(str => str.trim());

    return {
      streetAddress,
      apt,
      city,
      state,
      zip,
    };
  };

  const validateUsername = async username => {
    if (!username) return false;
    const allUsersData = await allUsers(bearerToken);
    return allUsersData.every(user => user.username.toLowerCase() !== username.toLowerCase() || user.id === profile.id);
  };

  const canUpload = () => !isLoadingPfp && !isUploading && hasChanged && userName && firstName && lastName;

  // Called when an entry field that is not an address field is changed
  const handleEntryChange = (val, setter) => {
    setHasChanged(true);
    setter(val);
  };

  // If we use S3 for pfps, this entire function can be changed
  const handleUploadPfp = event => {
    setHasChanged(true);

    setIsLoadingPfp(true);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setProfilePic(fileReader.result);
      setIsLoadingPfp(false);
    };
    const pfpFile = event.target.files[0];
    fileReader.readAsDataURL(pfpFile);
  };

  const handleAddressDetailsChange = addr => {
    setHasChanged(true);
    setAddressDetails(addr);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!canUpload()) {
      console.log('invalid entries');
      return;
    }

    setIsUploading(true);
    if (!(await validateUsername(userName))) {
      setOverlayMsg('Username already exists');
      overlayRef.current.present();
      setIsUploading(false);
      return;
    }

    const address = formatAddress();
    await updateProfile(bearerToken, {
      address,
      address_set: address !== '',
      birthdate,
      delivery_restaurant: profile.deliveryRestaurant,
      delivery_restaurant_id: profile.deliveryRestaurantId || '',
      fav_sports: profile.favSports.join(','),
      // change icon value to base64 encoding or S3 URL
      icon: '',
      user_id: profile.id,
      name: `${firstName} ${lastName}`,
      // disabling option to change phone number and email because it would require more complex verification
      phone_number: phoneNumber,
      // phone_number: profile.phoneNumber,
      username: userName,
      // email,
      email: profile.email,
      friends: profile.friends.join(','),
      // storing invaild longitude and latitude awaiting reset at home page
      // TODO: workaround to find existing long/lat
      longitude: 100.0,
      latitude: 200.0,
      devices: profile.devices,
    });
    await reload();
    dispatch({ type: 'TRIGGER_GET_CACHED_PROFILE' });
    setIsUploading(false);
  };

  useEffect(() => {
    if (profile === null) return;

    setUserName(profile.username || '');
    const splitName = profile.name?.split(' ').filter(str => str !== 'Unset value' && str !== '');
    const first = splitName ? splitName[0] : '';
    const last = splitName && splitName.length > 1 ? splitName[splitName.length - 1] : '';
    setFirstName(first);
    setLastName(last);
    // setEmail(isValidEmail(profile.email) ? profile.email : '');
    setPhoneNumber(parsePhoneNumber(profile.phoneNumber) || '');
    setBirthdate(profile.birthdate && profile.birthdate !== 'Unset value' ? profile.birthdate : '');
    const parsedAddress = parseFormattedAddress(profile.address);
    setAddressDetails(
      profile.addressSet && !parsedAddress.invalid
        ? parsedAddress
        : { streetAddress: '', apt: '', city: '', state: '', zip: '' },
    );
    setProfilePic(profile.icon || profilePicPlaceholder);
  }, [profile]);

  return (
    <IonPage className="edit-profile-page">
      <IonContent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => router.push('/app/profile', 'back')}>
              <IonIcon className="back-button-icon" slot="icon-only" icon={chevronBackOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle className="page-title">Edit Profile</IonTitle>
        </IonToolbar>
        {profile === null && (
          <form className="entry-options">
            <EditProfileLoader />
          </form>
        )}
        {profile !== null && (
          <>
            <img src={profilePic} className="profile-pic" alt="Profile Pic" />
            {/* Uncomment below to show "edit photo" text button */}
            {/* <p className="edit-photo" onClick={() => document.getElementById('edit-profile-hidden-file-input').click()}>Edit photo</p> */}
            <p className="info-label">Basic Information</p>
            <form className="entry-options" onSubmit={handleSubmit}>
              <input
                type="file"
                id="edit-profile-hidden-file-input"
                style={{ display: 'none' }}
                accept="image/png, image/jpeg"
                onChange={handleUploadPfp}
              />
              <p className="entry-label">User Name</p>
              <EntryField
                placeholder="Add your user name"
                type="text"
                value={userName}
                setter={val => handleEntryChange(val, setUserName)}
              />
              <p className="entry-label">First Name</p>
              <EntryField
                placeholder="Add your first name"
                type="text"
                value={firstName}
                setter={val => handleEntryChange(val, setFirstName)}
              />
              <p className="entry-label">Last Name</p>
              <EntryField
                placeholder="Add your last name"
                type="text"
                value={lastName}
                setter={val => handleEntryChange(val, setLastName)}
              />
              {/* <p className="entry-label">Email</p>
              <EntryField placeholder="Add your email" type="email" value={email} setter={setEmail} /> */}
              <p className="entry-label">Phone Number</p>
              <EntryField
                placeholder="No phone number found"
                type="tel"
                readonly
                value={phoneNumber}
                setter={val => handleEntryChange(val.replace(/\D/g, '').slice(0, 10), setPhoneNumber)}
              />
              <p className="entry-label">Birthdate</p>
              <EntryField
                placeholder="Add your birthday"
                type="date"
                value={birthdate}
                setter={val => handleEntryChange(val, setBirthdate)}
              />
              <p className="entry-label">Address</p>
              <AddressFields
                addressDetails={addressDetails}
                setAddressDetails={handleAddressDetailsChange}
                setIsFormValid={setIsAddressValid}
                showAutocompleteDefault={false}
              />
              <IonButton className="submit-button" shape="round" expand="block" type="submit" disabled={!canUpload()}>
                {(isLoadingPfp && 'Loading Profile Picture...') ||
                  (isUploading && 'Uploading Data...') ||
                  'Upload Data'}
              </IonButton>
            </form>
          </>
        )}
        <MessageOverlay blur ref={overlayRef} msg={overlayMsg} />
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
