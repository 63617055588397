import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { IonList, IonText } from '@ionic/react';
import LiveFightCard from './LiveFightCard';
import { getLiveBets } from '../../global/request/bet';
import { AuthContext } from '../authentication/AuthContext';
import useProfile from '../../global/useProfile';
import { LiveFightsLoader } from '../skeletalLoaders/SkeletalLoaders';
import generateUniqueKey from '../../UniqueKeyGenerator/UniqueKeyGenerator';

/**
 * Component for displaying a user's live fights in the profile page.
 * @param {Object} props
 * @param {Object[]} props.liveFights - Array of live fight objects.
 * @returns
 */
function LiveFights({ routeName }) {
  const [liveFightsData, setLiveFightsData] = useState([]);
  const [reloadProfile, profile] = useProfile();
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const fetchLiveFights = async () => {
    const bearerToken = authCtx.tokens.idToken;
    const updatedLiveFights = await getLiveBets(bearerToken);
    setLiveFightsData(updatedLiveFights);
    setIsLoading(false);
  };

  const formatName = (fullName, profileName) => {
    if (fullName === profileName) {
      return fullName;
    }

    const names = fullName?.trim()?.split(' ');
    if (names?.length > 1) {
      return `${names[0]} ${names[names.length - 1][0]}.`;
    }
    return fullName;
  };

  useEffect(() => {
    if (location.pathname !== routeName) {
      return;
    }
    fetchLiveFights();
    reloadProfile();
  }, [authCtx.tokens, location]);

  const pendingFights = liveFightsData.filter(fight => fight.accepted === 2);
  const onTableFights = liveFightsData.filter(fight => fight.accepted === 1);

  let pending;

  if (isLoading) {
    pending = (
      <div>
        {Array.from({ length: 2 }).map(() => (
          <LiveFightsLoader key={generateUniqueKey()} />
        ))}
      </div>
    );
  } else if (pendingFights && pendingFights.length > 0) {
    pending = pendingFights.map(fight => (
      <LiveFightCard
        fight={{
          bet: fight,
          sport: fight?.sport,
          makerName: fight?.makerName === profile?.name ? fight?.makerName : formatName(fight?.makerName),
          makerTeam: fight.makerOutcomeTeam,
          makerOutcomePhrase: fight?.makerOutcomePhrase,
          makerTeamColor: fight.makerOutcomeTeamColor,
          takerName: fight?.takerName === profile?.name ? fight?.takerName : formatName(fight?.takerName),
          takerTeam: fight.takerOutcomeTeam,
          takerTeamColor: fight.takerOutcomeTeamColor,
          takerOutcomePhrase: fight?.takerOutcomePhrase,
          restaurantId: fight.restaurantId,
          msg:
            profile?.name === fight?.makerName
              ? `${fight.makerOutcomePhrase} for ${fight.restaurantItems
                  .map(restItem => `x${restItem.quantity} ${restItem.name}`)
                  .join(', ')} at ${fight.restaurantName}`
              : `${fight.takerOutcomePhrase} for ${fight.restaurantItems
                  .map(restItem => `x${restItem.quantity} ${restItem.name}`)
                  .join(', ')} at ${fight.restaurantName}`,
          time: new Date(fight.timestamp * 1000).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
          }),
          pending: true,
          fightId: fight.id,
          address: fight.restaurantAddress,
        }}
        key={fight.id}
        onFightAction={fetchLiveFights}
        profileName={profile?.name}
        isAccepted={false}
      />
    ));
  } else {
    pending = <IonText className="no-fights-text">It&rsquo;s quite empty here... Start a FoodFight!</IonText>;
  }

  let inplay;

  if (isLoading) {
    inplay = (
      <div>
        {Array.from({ length: 2 }).map(() => (
          <LiveFightsLoader key={generateUniqueKey()} />
        ))}
      </div>
    );
  } else if (onTableFights && onTableFights.length > 0) {
    inplay = onTableFights.map(fight => (
      <LiveFightCard
        fight={{
          bet: fight,
          sport: fight?.sport,
          makerName: fight?.makerName === profile?.name ? fight?.makerName : formatName(fight?.makerName),
          makerTeam: fight.makerOutcomeTeam,
          makerOutcomePhrase: fight?.makerOutcomePhrase,
          makerTeamColor: fight.makerOutcomeTeamColor,
          takerName: fight?.takerName === profile?.name ? fight?.takerName : formatName(fight?.takerName),
          takerTeam: fight.takerOutcomeTeam,
          takerOutcomePhrase: fight?.takerOutcomePhrase,
          takerTeamColor: fight.takerOutcomeTeamColor,
          msg:
            profile?.name === fight?.makerName
              ? `${fight.makerOutcomePhrase} for ${fight.restaurantItems
                  .map(restItem => `x${restItem.quantity} ${restItem.name}`)
                  .join(', ')} at ${fight.restaurantName}`
              : `${fight.takerOutcomePhrase} for ${fight.restaurantItems
                  .map(restItem => `x${restItem.quantity} ${restItem.name}`)
                  .join(', ')} at ${fight.restaurantName}`,
          time: new Date(fight.timestamp * 1000).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
          }),
          pending: false,
          fightId: fight.id,
        }}
        key={fight.id}
        onFightAction={fetchLiveFights}
        profileName={profile?.name}
        isAccepted
      />
    ));
  } else {
    inplay = <IonText className="no-fights-text">It&rsquo;s quite empty here... Start a FoodFight!</IonText>;
  }

  return (
    <IonList id="live-list">
      <h2>Pending</h2>
      {pending}
      <h2>In-play</h2>
      {inplay}
    </IonList>
  );
}

export default LiveFights;
