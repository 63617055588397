/** @module global/request/user */
import RequestUtility from './request';

const SERVICE_URL = 'https://d2if4tc6g5.execute-api.us-east-1.amazonaws.com/prod';
const util = new RequestUtility(SERVICE_URL, {
  defaultMethod: 'GET',
});

// avoid error where friends field was initialized as array but changed to string in db
const getFriendsFromProfile = profile => {
  if (typeof profile.friends === 'string' && profile.friends.trim() !== '') {
    return profile.friends.split(',');
  }
  if (Array.isArray(profile.friends)) {
    return profile.friends;
  }
  return [];
};

/**
 *
 * @param {Object} profile
 * @returns {types.Profile}
 */
const transformProfile = profile => ({
  id: profile.user_id || 'Unset value',
  username: profile.username || 'Unset value',
  name: profile.name || 'Unset value',
  email: profile.email || 'Unset value',
  phoneNumber: profile.phone_number || 'Unset value',
  bio: profile.bio || 'Unset value',
  // I think friendCount isn't stored in backend
  // friendsCount: profile.friendsCount || 0,
  streak: profile.streak || 0,
  visited: profile.visited || 0,
  // favSports: (profile.fav_sports && [profile.fav_sports]) || [],
  favSports: typeof profile.fav_sports === 'string' ? profile.fav_sports.split(',') : [],
  totalFights: profile.fight_count || 0,
  wins: profile.win_count || 0,
  losses: profile.loss_count || 0,
  ties: profile.tie_count || 0,
  deliveryRestaurant: profile.delivery_restaurant || 'Unset value',
  deliveryRestaurantId: profile.delivery_restaurant_id || 0,
  address: profile.address || 'Unset value',
  addressSet: profile.address_set || 0,
  icon: profile.icon || null,
  birthdate: profile.birthdate || 'Unset value',
  friends: getFriendsFromProfile(profile),
  devices: profile.devices,
});

/**
 * Sends a request to the server to fetch the user's profile.
 * @param {string} bearerToken The bearer token to use for authentication. This should be the id token obtained from authentication
 * @returns
 */
async function getProfile(bearerToken) {
  const response = await util.authorizedRequest('user/profile', {
    bearerToken,
  });
  return transformProfile(response);
}

async function updateProfile(bearerToken, profile) {
  const response = await util.authorizedRequest('user/profile', { method: 'PUT', bearerToken }, profile);
  console.log('Response', response);
}

// XXX: temporary dev function - endpoint only works for admin accounts
async function allUsers(bearerToken) {
  const response = await util.authorizedRequest('user/profile/~', {
    bearerToken,
  });
  return response.map(profile => transformProfile(profile));
}

async function deleteUserData(bearerToken) {
  // eslint-disable-next-line no-unused-vars
  const response = await util.authorizedRequest('user/profile', {
    method: 'DELETE',
    bearerToken,
  });
}

async function updateAddress(bearerToken, address) {
  const addressEncoded = encodeURIComponent(address);

  try {
    const response = await util.authorizedRequest(`user/profile/address/${addressEncoded}`, {
      method: 'POST',
      bearerToken,
    });

    return response;
  } catch (error) {
    console.error('Failed to update address:', error);
    throw error;
  }
}

async function getReferalLink(bearerToken) {
  const response = await util.authorizedRequest('user/referral_signup', {
    bearerToken,
  });
  return response;
}

async function getFriendsProfile(bearerToken, user_id) {
  const response = await util.authorizedRequest(`user/profile/${user_id}`, {
    bearerToken,
  });
  return transformProfile(response);
}

async function incrementReferralCount(bearerToken, referralId) {
  const endpoint = `user/referral/${referralId}`;
  const response = await util.authorizedRequest(endpoint, {
    method: 'PUT',
    bearerToken,
  });

  return response;
}

async function updateLocation(bearerToken, longitude, latitude) {
  const longitudeEncoded = encodeURIComponent(longitude);
  const latitudeEncoded = encodeURIComponent(latitude);
  try {
    const response = await util.authorizedRequest(`user/profile/location/${longitudeEncoded}/${latitudeEncoded}`, {
      method: 'POST',
      bearerToken,
    });

    return response;
  } catch (error) {
    console.error('Failed to update location:', error);
    throw error;
  }
}

async function addFriend(bearerToken, friendId) {
  const response = await util.authorizedRequest(`user/add_friend/${friendId}`, {
    method: 'POST',
    bearerToken,
  });
  return response;
}

async function removeFriend(bearerToken, friendId) {
  const response = await util.authorizedRequest(`user/remove_friend/${friendId}`, {
    method: 'POST',
    bearerToken,
  });
  return response;
}

async function addDevice(bearerToken, device) {
  const response = await util.authorizedRequest(
    'user/add_device',
    {
      method: 'PUT',
      bearerToken,
    },
    { device_token: device },
  );
  return response;
}

async function removeDevice(bearerToken, device) {
  const response = await util.authorizedRequest(
    'user/remove_device',
    {
      method: 'PUT',
      bearerToken,
    },
    { device_token: device },
  );

  return response;
}

const exports = {
  getProfile,
  allUsers,
  updateProfile,
  deleteUserData,
  updateAddress,
  getReferalLink,
  getFriendsProfile,
  incrementReferralCount,
  updateLocation,
  addFriend,
  removeFriend,
  addDevice,
  removeDevice,
};

export {
  getProfile,
  allUsers,
  updateProfile,
  deleteUserData,
  updateAddress,
  getReferalLink,
  getFriendsProfile,
  incrementReferralCount,
  updateLocation,
  addFriend,
  removeFriend,
  addDevice,
  removeDevice,
};
export default exports;
