import React from 'react';
import { IonContent, IonList, IonItem, IonLabel } from '@ionic/react';
import './ChatContent.scss';

const formatTime = date => {
  const options = { hour: '2-digit', minute: '2-digit' };
  return new Date(date).toLocaleTimeString([], options);
};

const ChatContent = ({ messages, idData, isLoading }) => {
  return (
    <IonContent>
      <IonList className="chat-list">
        {messages.map(message => {
          const isSentByCurrentUser = message.creatorId === idData.sub;
          return (
            <IonItem key={message.messageId} className={`message-item ${isSentByCurrentUser ? 'sent' : 'received'}`}>
              <p>{message.data.text}</p>
              <IonLabel className="message-time">{formatTime(message.createdAt)}</IonLabel>
            </IonItem>
          );
        })}
      </IonList>
      {isLoading && <p>Loading...</p>}
    </IonContent>
  );
};

export default ChatContent;
